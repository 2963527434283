
.frame-type-wvier_headerimage {
    position: relative;
}

.header-image {
    position: relative;
    width: 100%;
    background-color: $gray-200;
    overflow: hidden;
    height: 275px;

    @include media-breakpoint-up(md){
        height: 630px;
    }

    picture {
        display: block;
        height: 100%;
        width: 100vw;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
